$theme-colors: (
  "morsmad-green": #939B00,
  "morsmad-navbar": #7F8400
);

// https://css-tricks.com/snippets/css/font-stacks/
$font-family-base: Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;
$font-size-base: 0.9rem;

$navbar-dark-color: white;

$link-color: #B96D00;

@import "~bootstrap/scss/bootstrap";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.css";
@import "shared.scss";

body {
  background: #000000 url('images/wallpaper.jpg');

  > .container {
    background-color: #fff;
  }

}

@include media-breakpoint-up(md) {
  body > .container {
    border: solid 7px #fff;
  }
}

@include media-breakpoint-only(sm) {
  body > .container {
    max-width: 100%;
  }
}

.bg-gray-gradient {
  background: #2B2B2B url('images/gray-gradient.gif') repeat-x;

  h1 {
    font-weight: normal;
    color: #fff;
    font-size: 2.5em;
  }

  h2 {
    font-size: 1.0em;
    font-weight: normal;
    color: #aaa;
  }
}

.headerpic {
  height: 100px;
  background: black url('images/fruits-and-vegetables.jpg') no-repeat top center;
}

div.border1 {
  background: #fff url('images/border1.gif') repeat-x;
  height: 14px;
}

div.border1r {
  background: #fff url('images/border1r.gif') repeat-x;
  height: 14px;
}

div.divider1 {
  background: #fff url('images/border2.gif') repeat-x;
  height: 14px;
}



ul.frontpage-list {
  line-height: 1.5em;
  list-style: square outside;
  color: #939B00;
}

div.divider2 {
  height: 1px;
  border-bottom: solid 1px $gray-300;
  margin: 2.0em 0 2.0em 0;
}

div.recipe {
  cursor: pointer;

  .badge {
    position: absolute;
    top: 1px;
    right: 5px;
    background: rgba(255, 255, 255, 0.8);
  }

  div.recipeimage {
    background: #f0f0f0 url('images/photo-missing.png') center;
    background-size: cover;
    width: 100%;
    height: 150px;
  }

  .recipetags {
    font-size: 1em;
    cursor: default;
  }
}

div.category {
  cursor: pointer;
}

@include media-breakpoint-down(sm) {
  li.nav-item:active {
    background-color: theme-color(morsmad-green);
  }

  div.recipe {
    margin-top: -4px;
    padding-left: 60px;
    min-height: 55px;
    border-bottom: 1px solid $gray-300;

    div.recipeimage {
      position: absolute;
      margin-left: -60px;
      width: 50px;
      height: 50px;
    }

    &:active {
        background-color: #f0f0f0;
      }
  }

  div.category {
    min-height: 46px;
    border-bottom: 1px solid $gray-300;

    &:active {
      background-color: #f0f0f0;
    }
  }

}

#enterWorkmode, #exitWorkmode {
  cursor: pointer;
}

h3.workmode {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 25px;
}

[data-toggle="tooltip"] {
  cursor: default;
}